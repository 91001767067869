import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, HashRouter } from 'react-router-dom';
import PageRoutes from './components/routes/pageroutes';
import { connect } from 'react-redux';
import PublicRoutes from './components/routes/publicroutes';
import AdminRoutes from './components/routes/adminroutes';


function App(props) {

  const checkPage = () => {
    if (props.JobApplicant.length > 0) {
      return <PageRoutes />
    } else if (props.AdminLoginData.length > 0 || props.Admin.length > 0) {
      return <AdminRoutes />

    } else {
      return <PublicRoutes />
    }
  }

  return (
    <div className="">

      <Router>

        {checkPage()}
        {/* {
          props.JobApplicant.length > 0 || props.Admin.length > 0 ? <PageRoutes /> : props.AdminLoginData.length > 0 ? <AdminRoutes /> : <PublicRoutes />
        } */}

      </Router>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    JobApplicant: state.JobApplicantData,
    AdminLoginData: state.AdminLoginData,
    Admin: state.Admin


  };
};
export default connect(mapStateToProps, null)(App);
