

export const serverStatus = "Live";

export const serverLink = serverStatus === "Dev" ?
    "http://192.168.1.75:4480"
    // : "https://smartsourcingapi.com:5001/" //LUX MUNDI
    // : "https://smartsourcingapi.com:5002/" // BABA AHMED
    // : "https://smartsourcingapi.com:5003/" // AL-ANSAR
    // : "https://smartsourcingapi.com:5004/" // OLIVIA 
    : "https://gargatechapi.com.ng:5008" //COSMOPOLITAN